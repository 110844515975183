export const NavComponent = (props) => {
    return (
        <div>
            <nav className="navbar">
                <div className="navbar-logo">
                    <img src="" className="navbar-logo-img" alt="Logo" />
                    <p className="navbar-logo-text">Creativblume</p>
                </div>
                <diov className="navbar-nav">
                    <ul>
                       <li className="navbar-nav-item">
                        <a href="#" className="navbar-nav-link">Startseite</a>
                        </li>
                        <li className="navbar-nav-item">
                            <a href="#" className="navbar-nav-link">Über uns</a>
                        </li>
                        <li className="navbar-nav-item">
                        <a href="#" className="navbar-nav-link">Gallerien</a> 
                        </li> 
                        <li className="navbar-nav-item">
                        <a href="#" className="navbar-nav-link">Impressum</a>
                        </li>
                    </ul>
                </diov>
            </nav>
        </div>
    )
};