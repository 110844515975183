import logo from './logo.svg';
import './App.css';
import {NavComponent} from './navBar';
import {Page} from './page';
import {Gallery} from './gallery';
import {Footer} from './footer';

function App() {
  return (
    <div>
      <NavComponent />
      <Footer />
    </div>
  );
}

export default App;
